<template>
  <div class="wrraper">
    <!-- Start Breadcrumb -->
    <Breadcrumb
      :items="items"
      search_route="/magazine/add"
      :search_title="$t('addNew')"
      icon="fa-plus"
    />
    <!-- End Breadcrumb -->
    <!-- <main-filter @getResult="setRows" :code="true" /> -->
    <!-- <MainLoader v-if="loading"></MainLoader> -->
    <div class="fadeIn">
      <!-- Start Main Section -->
      <main>
        <v-data-table
          class="elevation-1 thumb diff_table"
          :headers="headers"
          :items="rows"
          :search="search"
          :loading="loading"
          :loading-text="$t('table.loadingData')"
          item-key="id"
          :items-per-page="paginations.items_per_page"
          hide-default-footer
        >
          <!-- ================== You Can use any slots you want ================== -->
          <!-- Select no data State -->

          <template v-slot:no-data>
            {{ $t("table.noData") }}
          </template>

          <template v-slot:[`item.index`]="{ index }">
            {{ index + 1 }}
          </template>

          <!-- Start:: Users Routes -->
          <template v-slot:[`item.media`]="{ item }">
            <img
              v-if="item.media.length"
              :src="item.media[0].image"
              :alt="item.name"
              class="image"
              @click="show_model_1"
            />
            <img
              v-else-if="item.product_details"
              :src="item.product_details[0].media[0].image"
              :alt="item.name"
              class="image"
              @click="show_model_1"
            />
            <span v-else>{{ $t("notFound") }}</span>
          </template>
          <template v-slot:[`item.desc`]="{ item }">
            <div
              class="item-with-img d-flex justify-content-center align-items-center"
            >
              <!-- <p>
                {{ item.desc ? item.desc.slice(0, 40) : '' }}
              </p> -->

              <v-icon
                class="show"
                v-if="item.desc"
                small
                @click="showDesc(item)"
              >
                fal fa-eye
              </v-icon>
            </div>
          </template>
          <template v-slot:[`item.is_active`]="{ item }">
            <span
              class="status"
              :class="item.is_active ? 'success' : 'canceled'"
            >
              {{ item.is_active ? $t("active") : $t("inactive") }}
            </span>
          </template>

          <!-- Start:: Report Reason Modal Button -->
          <template v-slot:[`item.report_reason`]="{ item }">
            <v-icon class="show" small v-if="item.report_reason">
              fal fa-eye
            </v-icon>

            <span v-else>_</span>
          </template>
          <!-- End:: Report Reason Modal Button -->

          <!-- Select actions-->
          <template v-slot:[`item.actions`]="{ item }">
            <div class="_actions d-flex justify-content-center">
              <!-- <v-icon class="show" small @click="showItem(item)">
                fal fa-eye
              </v-icon> -->
              <v-icon class="show" small @click="editItem(item)">
                fal fa-edit
              </v-icon>
              <!-- <v-icon class="edit" small @click="editItem(item)">
                  fal fa-edit
                </v-icon> -->
              <v-icon class="delete" small @click="deleteItem(item)">
                fal fa-trash
              </v-icon>
            </div>
          </template>

          <!-- ======================== Start Top Section ======================== -->
          <template v-slot:top>
            <div class="d-flex justify-content-between align-items-center">
              <h3 class="table-title title">{{ $t("titles.magazines") }}</h3>
              <!-- Delete dialog -->
              <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card>
                  <v-card-title class="text-h5 justify-center">
                    {{ $t("table.deletedialog.areYouSure") }}
                  </v-card-title>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="#1B5E20" @click="deleteItemConfirm">
                      {{ $t("table.deletedialog.ok") }}
                    </v-btn>
                    <v-btn color="#F44336" @click="dialogDelete = false">
                      {{ $t("table.deletedialog.cancel") }}
                    </v-btn>
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <!-- <div class="px-5 pt-3 frm-action d-flex flex-row gap-2">
                <a
                  class="export btn"
                  href="https://shams-pharmacis.products.aait-d.com/api/dashboard/products-export"
                  download="true"
                >
                  <span> Download Sheet <i class="fal fa-download"></i></span>
                </a>
                <button class="export btn" @click="importModel = true">
                  <span> Import <i class="fal fa-upload"></i></span>
                </button>
                <button class="export btn">
                  <export-excel
                    :fetch="exportFields"
                    :meta="json_meta"
                    :name="$t(`titles.products`)"
                    :worksheet="$t(`titles.products`)"
                  >
                    Export <i class="fal fa-download"></i
                  ></export-excel>
                </button>
              </div> -->
            </div>
          </template>
          <!-- ======================== End Top Section ======================== -->
        </v-data-table>
      </main>

      <!-- End Statistics Card-->

      <!-- Start Pagination -->
      <template>
        <div
          class="pagination_container text-center mb-5 d-flex justify-content-end"
        >
          <!-- <div class="select-pagination d-flex">
            <span class="first">{{ $t('show') }}</span>
            <v-select
              :items="[5, 20, 50, 100]"
              v-model="paginations.items_per_page"
            ></v-select>
            <span>{{ $t('entries') }}</span>
          </div> -->

          <v-pagination
            color="primary"
            v-model="paginations.current_page"
            :length="paginations.last_page"
            :total-visible="7"
            @input="fetchData($event)"
          ></v-pagination>
        </div>
      </template>
      <!-- End Pagination -->
    </div>
    <!-- Start Image_Model -->
    <base-model
      @closeModel="model_1.show_model = false"
      :show="model_1.show_model"
    >
      <div class="image">
        <img
          v-if="model_1.model_img_src"
          :src="model_1.model_img_src"
          alt="..."
        />
      </div>
    </base-model>
    <base-model
      @closeModel="model_2.show_model = false"
      :show="model_2.show_model"
    >
      <div class="custom_card diff">
        <h4>وصف المنتج</h4>
        <p>{{ model_2.desc }}</p>
      </div>
    </base-model>
    <!-- <v-dialog v-model="importModel" width="500">
      <v-card>
        <v-card-text>
          <div class="input_wrapper top_label file_input">
            <span class="file_input_label"> تحميل الملف </span>
            <label
              for="file_input_1"
              class="form-label"
              v-if="!selectedFile.file"
            ></label>
            <label for="file_input_1" class="form-label" v-else>
              {{ selectedFile.name }}
            </label>
            <input
              type="file"
              class="form-control"
              id="file_input_1"
              @change="handelSelectedFile"
            />
          </div>

        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="#1B5E20"
            @click="importFileConfirm"
            :loading="importLoading"
          >
            {{ $t("table.deletedialog.ok") }}
          </v-btn>
          <v-btn color="#F44336" @click="importModel = false">
            {{ $t("table.deletedialog.cancel") }}
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog> -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import MainFilter from "../../../components/Filters/MainFilter.vue";

export default {
  components: { MainFilter },
  data() {
    return {
      // ========== Breadcrumbs
      items: [
        {
          text: this.$t("breadcrumb.mainPage"),
          disabled: false,
          href: "/",
        },
        {
          text: this.$t("breadcrumb.magazine.title"),
          disabled: false,
          href: "/magazine",
        },
        {
          text: this.$t("breadcrumb.magazine.all"),
          disabled: true,
          href: "",
        },
      ],

      // ========== Statistics
      statisticsItem: {},

      // ========== Top Section
      search: "",

      // ========== dialog Status
      dialogDelete: false,
      dialogDelete_selected: false,
      itemtoDelete: null,

      // ========== Model
      model_1: {
        show_model: false,
        model_img_src: "",
      },
      model_2: {
        show_model: false,
        desc: "",
      },

      // ========== Your Data
      rows: [], // injected in created

      // ========== elected Data
      selected: [],
      singleSelect: false,

      // ========== pagination
      paginations: {
        current_page: 1,
        last_page: 1,
        items_per_page: 15,
      },
      selectedFile: {
        file: null,
        name: null,
        path: null,
      },
      showItems: [
        {
          id: 1,
          value: 5,
        },
        {
          id: 2,
          value: 10,
        },
        {
          id: 1,
          value: 20,
        },
      ],

      // ========== Loding
      loading: false,
      importModel: false,
      importLoading: false,
      json_meta: [
        [
          {
            key: "charset",
            value: "utf-8",
          },
        ],
      ],
    };
  },

  computed: {
    ...mapGetters({
      lang: "lang_module/lang",
    }),
    headers() {
      if (this.lang == "ar") {
        return [
          {
            text: "#",
            align: "center",
            value: "index",
            sortable: true,
          },
          {
            text: "الصورة الرئيسية",
            align: "center",
            value: "media",
            sortable: true,
          },
          // {
          //   text: "الاسم",
          //   align: "center",
          //   value: "name",
          //   sortable: true,
          // },

          // {
          //   text: "ترتيب العرض",
          //   align: "center",
          //   value: "ordering",
          //   sortable: true,
          // },
          // {
          //   text: "الوصف",
          //   align: "center",
          //   value: "desc",
          //   sortable: true,
          // },
          // {
          //   text: "الكود",
          //   align: "center",
          //   value: "code",
          //   sortable: true,
          // },
          {
            text: "الحالة",
            align: "center",
            value: "is_active",
            sortable: true,
          },
          {
            text: "الاجراءات",
            align: "center",
            value: "actions",
            sortable: true,
          },
        ];
      } else {
        return [
          {
            text: "#",
            align: "center",
            value: "index",
            sortable: true,
          },
          {
            text: "Main Image",
            align: "center",
            value: "media",
            sortable: true,
          },
          // {
          //   text: "Name",
          //   align: "center",
          //   value: "name",
          //   sortable: true,
          // },

          // {
          //   text: "Ordering",
          //   align: "center",
          //   value: "ordering",
          //   sortable: true,
          // },
          // {
          //   text: "Description",
          //   align: "center",
          //   value: "desc",
          //   sortable: true,
          // },
          // {
          //   text: "Code",
          //   align: "center",
          //   value: "code",
          //   sortable: true,
          // },
          {
            text: "Status",
            align: "center",
            value: "is_active",
            sortable: true,
          },
          {
            text: "Actions",
            align: "center",
            value: "actions",
            sortable: true,
          },
        ];
      }
    },
  },

  methods: {
    async exportFields() {
      const data = await this.$axios({
        method: "GET",
        url: "get_all_product",
      });
      let newData = [];
      data.data.data.forEach((el) => {
        let newObject = {};
        newObject[`${this.$t("forms.labels.image")}`] = el?.media.length
          ? el.media[0].image
          : this.$t("notFound");
        newObject[`${this.$t("forms.labels.name")}`] = el.name;
        newObject[`${this.$t("forms.labels.ordering")}`] = el.ordering;
        newObject[`${this.$t("forms.labels.desc")}`] = el.desc;
        newObject[`${this.$t("forms.labels.code")}`] = el.code;
        newObject[`${this.$t("forms.labels.status")}`] = el.is_active
          ? this.$t("active")
          : this.$t("inactive");
        // newObject[`${this.$t("forms.labels.city")}`] = el.city;
        newData.push(newObject);
      });
      return newData;
    },
    // exportProducts() {
    //   this.$axios.get("products-export");
    // },

    importFileConfirm() {
      if (!this.selectedFile.file) {
        this.$iziToast.error({
          timeout: 2000,
          message: "يرجي اختيار الملف اولا ",
          position: "bottomRight",
        });
        return;
      } else {
        this.importLoading = true;
        const frmData = new FormData();
        frmData.append("products_file", this.selectedFile.file);
        this.$axios
          .post("products-import", frmData)
          .then(() => {
            this.importModel = false;
            this.importLoading = false;
            this.$iziToast.success({
              timeout: 2000,
              message: "تم التحميل بنجاح",
              position: "bottomRight",
            });
            this.selectedFile = {
              file: null,
              name: null,
              path: null,
            };
            this.setRows();
          })
          .catch((err) => {
            // this.importModel = false;
            this.importLoading = false;
            this.$iziToast.error({
              timeout: 2000,
              message: err.response.data.message,
              position: "bottomRight",
            });
          });
      }
    },
    // ===== Search Method =====
    filterClick(word) {
      if (!this.loading) {
        this.search = word;
        this.helper_filterSearch();
      }
    },

    // img Model
    show_model_1(e) {
      this.model_1.model_img_src = e.target.src;
      this.model_1.show_model = true;
    },
    showDesc(e) {
      console.log(e);
      this.model_2.desc = e.desc;
      this.model_2.show_model = true;
    },

    // ==================== Start CRUD ====================
    addItem() {
      this.$router.push({ path: "/magazine/add" });
    },
    showItem(item) {
      this.$router.push({ path: "/magazine/show/" + item.id });
    },
    editItem(item) {
      this.$router.push({ path: "/magazine/edit/" + item.id });
    },

    // ===== Delete
    deleteItem(item) {
      this.dialogDelete = true;
      this.itemtoDelete = item;
    },
    deleteItemConfirm() {
      this.$axios({
        method: "DELETE",
        url: `products/${this.itemtoDelete.id}`,
      })
        .then(() => {
          this.rows = this.rows.filter((item) => {
            return item.id != this.itemtoDelete.id;
          });
          this.dialogDelete = false;
          this.$iziToast.success({
            timeout: 2000,
            message: this.$t("deleteSuccess"),
            position: "bottomRight",
          });
        })
        .catch((err) => {
          this.dialogDelete = false;
          this.$iziToast.error({
            timeout: 2000,
            message: err.response.data.message,
            position: "bottomRight",
          });
        });
    },
    deleteSelected() {
      this.dialogDelete_selected = true;
    },
    deleteSelectedConfirm() {
      this.$axios({
        method: "POST",
        url: `products/deleteAll`,
        data: { ids: this.selected.map((item) => item.id) },
      })
        .then(() => {
          this.rows = this.rows.filter((item) => {
            return !this.selected.filter((obj) => obj.id == item.id).length > 0;
          });
          this.dialogDelete_selected = false;
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("deleteSuccess"),
            position: "bottomRight",
          });
        })
        .catch((err) => {
          this.dialogDelete_selected = false;
          this.$iziToast.error({
            timeout: 2000,
            message: err.response.data.message,
            position: "bottomRight",
          });
        });
    },
    // ==================== End CRUD ====================
    handelSelectedFile(e) {
      this.selectedFile.file = e.target.files[0];
      this.selectedFile.name = e.target.files[0].name;
      this.selectedFile.path = URL.createObjectURL(e.target.files[0]);
    },
    // Set Rows
    setRows(e) {
      this.loading = true;
      if (e) {
        console.log(e);
        // this.paginations.current_page = 1;
      }
      const params = new URLSearchParams();
      params.append("page", this.paginations.current_page);

      this.$axios({
        method: "GET",
        url: "magazine",
        params: params,
      })
        .then((res) => {
          this.paginations.last_page = res.data.meta?.last_page;
          this.paginations.items_per_page = res.data.meta?.per_page;
          this.rows = res.data.data;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    fetchData(e) {
      this.$router.replace({ query: { page: e } });
      this.setRows();
    },
  },

  // ======= hooks
  created() {
    if (this.$route.query.page) {
      this.paginations.current_page = +this.$route.query.page;
    }
    this.setRows();
  },
};
</script>

<style lang="scss" scoped>
.model_container .main_body .image {
  width: 120px;
  img {
    max-height: 80vh;
    // object-fit: contain;
    object-fit: contain;
    max-width: 440px;
    display: block;
    min-width: unset;
    max-width: 500px;
  }
}
.custom_card.diff {
  max-width: 600px;
  min-width: 600px;
  border: 2px solid var(--mainColor-2);
  h4 {
    font-size: 19px;
    text-align: center;
    color: var(--mainColor-2);
  }
  p {
    font-size: 15px;
    text-align: center;
    word-break: break-all;
  }
}
</style>
